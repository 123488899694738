export const windowLoaded = () => typeof window !== 'undefined';
export const isTouchDevice = () =>
  (windowLoaded() && 'ontouchstart' in window) ||
  (typeof navigator !== 'undefined' && navigator.maxTouchPoints > 0);

export const isDev = (): boolean =>
  windowLoaded() && window.location.hostname === 'localhost';

export const shouldMockResponse = (): boolean => {
  if (!isDev()) {
    return false;
  }
  if (windowLoaded()) {
    const searchParams = new URL(window.location.href).searchParams;
    return searchParams.get('useMocks') === 'true';
  }
  return false;
};
type TrackProperties = {
  value: string | number;
};
export const track = (name: string, properties?: TrackProperties) => {
  if (isDev()) {
    // eslint-disable-next-line no-console
    console.log('Track:', name, properties);
    return;
  }
  if (windowLoaded()) {
    window.splitbee?.track(name, properties);
  }
};

export const getDays = (dateToCompare: string, ignorePastDate = true) => {
  const oneHour = 60 * 60 * 1000;
  const oneDay = 24 * oneHour; // hours*minutes*seconds*milliseconds
  const dateNow = new Date().valueOf();
  const secondDate = new Date(dateToCompare).valueOf();
  const dateDifference = dateNow - secondDate;
  if (ignorePastDate) {
    if (dateDifference > 0) {
      return '0 hours';
    }
  }
  const days = Math.round(Math.abs(dateDifference / oneDay));
  if (days === 1) {
    return `${days} day`;
  } else if (days < 1) {
    const hours = Math.round(Math.abs(dateDifference / oneHour));
    return `${hours} ${hours <= 1 ? 'hour' : 'hours'}`;
  }
  return `${days} days`;
};

export const getFormattedDate = (date: string) =>
  new Date(date).toDateString().replace(/^\S+\s/, '') +
  ' at ' +
  new Date(date).toLocaleTimeString();

export const getDateInfo = (
  type: string,
  start: string | undefined,
  end: string | undefined
) => {
  if (['gift'].includes(type)) {
    if (!end) {
      return;
    }
    return {
      display: `${getDays(end)} left to claim`,
      tooltip: `ends on ${getFormattedDate(end)}`,
    };
  }
  if (['leaving_soon'].includes(type)) {
    if (!end) {
      return;
    }
    return {
      display: `${getDays(end)} left to play`,
      tooltip: `leaves on ${getFormattedDate(end)}`,
    };
  }
  if (['coming_soon'].includes(type)) {
    if (!start) {
      return;
    }
    return {
      display: `${getDays(start)} till available`,
      tooltip: `available on ${getFormattedDate(start)}`,
    };
  }
  if (['new_additions'].includes(type)) {
    if (!start) {
      return;
    }
    return {
      display: `added ${getDays(start, false)} ago`,
      tooltip: `added on ${getFormattedDate(start)}`,
    };
  }
  return;
};

export const compare = (property: string) => (a: object, b: object) => {
  const key = property as keyof object;
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};
