import {
  SETTINGS_STATE,
  SETTING_CATALOGUE_STATE,
  SETTING_STORES_ORDER_STATE,
  SETTING_STORES_STATE,
} from '../actions/types';

export interface SettingsState {
  stores: {
    [key: string]: boolean;
  };
  catalogue: {
    [key: string]: boolean | string;
  };
  region: string;
  language: string;
  storesOrder: string[];
}

export const initialState: SettingsState = {
  region: 'US',
  language: 'en',
  catalogue: {
    saved: false,
    sortBy: 'name',
  },
  stores: {
    epic: true,
    psplus_essential: true,
    psplus_extra: true,
    psplus_premium: true,
    steam: true,
    xboxgp_pc: true,
    xboxgp_console: true,
    netflix: true,
  },
  storesOrder: [
    'epic',
    'steam',
    'xboxgp_pc',
    'xboxgp_console',
    'psplus_essential',
    'psplus_extra',
    'psplus_premium',
    'netflix',
  ],
};

interface Action {
  type?: string;
  payload?: object;
}

const reducer = (
  state: SettingsState = initialState,
  action: Action = {}
): SettingsState => {
  const { type, payload } = action;

  switch (type) {
    case SETTINGS_STATE:
      return {
        ...state,
        ...payload,
      };
    case SETTING_STORES_STATE:
      return {
        ...state,
        stores: {
          ...state.stores,
          ...payload,
        },
      };
    case SETTING_STORES_ORDER_STATE:
      return {
        ...state,
        storesOrder: payload as string[],
      };
    case SETTING_CATALOGUE_STATE:
      return {
        ...state,
        catalogue: {
          ...(state.catalogue || {}),
          ...payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
