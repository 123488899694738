import { combineReducers } from 'redux';

import settingsReducer, { SettingsState } from './settings';

export default combineReducers({
  settings: settingsReducer,
});

export interface Store {
  settings: SettingsState;
}
