// extracted by mini-css-extract-plugin
export var contentEven = "#232323";
export var contentOdd = "#353535";
export var error = "red";
export var errorDarker = "#d40000";
export var greyLight = "#5a5a5a";
export var headerEven = "#272626";
export var headerOdd = "#303030";
export var input = "Input-module--input--2494a";
export var primary = "#492ead";
export var primaryDarker = "#381d9d";
export var secondary = "#d68a00";
export var secondaryDarker = "#c67800";
export var success = "green";
export var textDefault = "#fff";
export var textInactive = "#8e8e8e";
export var theme = "#121212";