import { AiTwotoneSetting } from 'react-icons/ai';
import {
  FaBars,
  FaBug,
  FaCalendarCheck,
  FaCalendarTimes,
  FaCheckCircle,
  FaGift,
  FaGripVertical,
  FaPlaystation,
  FaQuestion,
  FaRegClock,
  FaSearch,
  FaSteam,
  FaSyncAlt,
  FaTimes,
  FaXbox,
  FaArrowUp,
  FaQuestionCircle,
} from 'react-icons/fa';
import { SiEpicgames, SiNetflix } from 'react-icons/si';

const Icons = {
  menu: FaBars,
  close: FaTimes,
  question: FaQuestion,
  questionCircle: FaQuestionCircle,
  bug: FaBug,
  check: FaCheckCircle,
  gift: FaGift,
  leaving_soon: FaCalendarTimes,
  new_additions: FaCalendarCheck,
  coming_soon: FaRegClock,
  playstation: FaPlaystation,
  netflix: SiNetflix,
  xbox: FaXbox,
  steam: FaSteam,
  epic: SiEpicgames,
  settings: AiTwotoneSetting,
  drag: FaGripVertical,
  search: FaSearch,
  refresh: FaSyncAlt,
  upArrow: FaArrowUp,
};

export type IconType = keyof typeof Icons;

interface IconProps {
  size?: number;
  color?: string;
  icon: IconType;
}

const defaultIconSize = 22;
export const Icon = ({ color, size = defaultIconSize, icon }: IconProps) => {
  const component = { icon: Icons[icon] };
  return <component.icon size={size} color={color} />;
};
