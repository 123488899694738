import * as styles from './Input.module.scss';

interface InputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> {
  onChange: (value: string) => void;
  rows?: number;
  name: string;
}
export const Input = ({ rows, onChange, value, ...inputProps }: InputProps) => (
  <>
    {rows ? (
      <textarea
        className={styles.input}
        placeholder={inputProps?.placeholder}
        autoFocus={inputProps?.autoFocus}
        rows={rows}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    ) : (
      <input
        type={'text'}
        value={value}
        onChange={e => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        className={styles.input}
        {...inputProps}
      />
    )}
  </>
);
