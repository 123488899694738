import { useEffect, useState } from 'react';

import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import './styles/core.scss';

interface AppProps {
  children: React.ReactNode;
}

export const App = ({ children }: AppProps) => {
  const [hasLoaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!hasLoaded) {
    return null;
  }
  return <ErrorBoundary>{children}</ErrorBoundary>;
};
