// extracted by mini-css-extract-plugin
export var alert = "Button-module--alert--6c2be";
export var button = "Button-module--button--35ae4";
export var contentEven = "#232323";
export var contentOdd = "#353535";
export var disabled = "Button-module--disabled--eec61";
export var error = "red";
export var errorDarker = "#d40000";
export var fullWidth = "Button-module--fullWidth--fd8ac";
export var greyLight = "#5a5a5a";
export var headerEven = "#272626";
export var headerOdd = "#303030";
export var lg = "Button-module--lg--25bfb";
export var primary = "Button-module--primary--b0c6a";
export var primaryDarker = "#381d9d";
export var secondary = "Button-module--secondary--3472a";
export var secondaryDarker = "#c67800";
export var sm = "Button-module--sm--7cb84";
export var success = "green";
export var textDefault = "#fff";
export var textInactive = "#8e8e8e";
export var theme = "#121212";